<template>
    <div class="form-container">
      <h2>流程申请表单</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="visitorName">访客姓名:</label>
          <input type="text" v-model="formData.visitorName" id="visitorName" required>
        </div>
        <div class="form-group">
          <label for="contact">联系方式:</label>
          <input type="text" v-model="formData.contact" id="contact" required>
        </div>
        <div class="form-group">
          <label for="department">申请处室:</label>
          <select v-model="formData.department" id="department" required>
            <option v-for="dept in departments" :key="dept.id" :value="dept.name">{{ dept.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="diningTime">就餐时间:</label>
          <input type="datetime-local" v-model="formData.diningTime" id="diningTime" required>
        </div>
        <div class="form-group">
          <label for="approver">选择经办人:</label>
          <select v-model="formData.approver" id="approver" required>
            <option v-for="approver in approvers" :key="approver.id" :value="approver.name">{{ approver.name }}</option>
          </select>
        </div>
        <button type="submit">提交申请</button>
      </form>
      <div v-if="submissionStatus" class="status-message">
        {{ submissionStatus }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        formData: {
          visitorName: '',
          contact: '',
          department: '',
          diningTime: '',
          approver: ''
        },
        departments: [
          { id: 1, name: '部门 A' },
          { id: 2, name: '部门 B' },
          { id: 3, name: '部门 C' }
        ],
        approvers: [
          { id: 1, name: '经办人 A' },
          { id: 2, name: '经办人 B' },
          { id: 3, name: '经办人 C' }
        ],
        submissionStatus: ''
      };
    },
    methods: {
      submitForm() {
        // 模拟表单提交
        console.log('提交的数据:', this.formData);
        this.submissionStatus = '申请已提交。';
        
        // 发起人处室经办人审批逻辑
        // 后勤服务中心审批分配逻辑
      }
    }
  };
  </script>
  
  <style scoped>
  .form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input, select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .status-message {
    margin-top: 20px;
    font-size: 16px;
    color: green;
  }
  </style>
  